<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row class="mt-2" v-show="display === 'block' ? true : false">
            <v-col cols="12">
                <v-card class="rounded-l" outlined>
                    <v-card-title>
                        CRC Procurement
                        <v-spacer></v-spacer>
                        <v-btn
                        small
                        color="#fff"
                        class="py-5 mr-3"
                        @click="getPullData()"
                        >
                            <v-icon>mdi-cached</v-icon>
                        </v-btn>
                        <v-btn
                        small
                        color="#fff"
                        class="py-5 mr-3"
                        @click="dialog = true"
                        >
                            <v-icon>mdi-filter-variant</v-icon>
                        </v-btn>
                        <v-dialog
                            v-model="dialog"
                            max-width="600px"
                            persistent
                        >
                            <v-card>
                                <v-card-title class="p-1">                        
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        x-small
                                        color="#e83e8c;"
                                        @click="dialog = false"
                                    >
                                        <v-icon dark>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <v-container fluid fluid>
                                        <v-row>
                                            <v-col class="col-12" sm="6" md="6">
                                                <h6 class="blue-lcd mb-1">Thick</h6>
                                                <v-autocomplete
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="thick"
                                                    :items="thicks"
                                                    item-value="thick"
                                                    :item-text="item => $store.getters.convertToCurrencyUs(item.thick)"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    
                                                ></v-autocomplete> 
                                            </v-col>
                                            <v-col class="col-12" sm="6" md="6">
                                                <h6 class="blue-lcd mb-1">Width</h6>
                                                <v-autocomplete
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="width"
                                                    :items="widths"
                                                    item-value="Width"
                                                    :item-text="item => $store.getters.convertToCurrencyUs(item.Width)"
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col class="col-12" sm="6" md="6">
                                                <h6 class="blue-lcd mb-1">Year</h6>
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="320px"
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            dense
                                                            v-model="year"
                                                            label="Year"
                                                            append-icon="event"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            solo
                                                            class="ma-0 pa-0 border-12"
                                                            readonly
                                                            hide-details=true
                                                            >
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        ref="picker"
                                                        v-model="date"
                                                        :max="picker"
                                                        @input="save"
                                                        reactive
                                                        no-title>
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col class="col-12" sm="6" md="6">
                                                <h6 class="blue-lcd mb-1">Wgt OH</h6>
                                                <v-checkbox
                                                v-model="wgt_oh_check"
                                                label="Wgt OH"
                                                class="ma-0 pa-2 border-12"
                                                hide-details=true
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="text-center">
                                    <v-container fluid fluid>
                                        <v-row justify="center">
                                            <v-col cols="12" xs="12" sm="3" md="3">
                                                <v-btn block class="rounded-l mr-2" color="#fff" @click="clear()">Clear</v-btn>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="3" md="3">
                                                <v-btn block class="rounded-l text-white" color="success" @click="[getPullData(), dialog = false]">Search</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <div class="w-100">
                            <v-divider></v-divider>
                        </div>
                        <div style="width: 100%;padding-top: 0;padding-bottom: 0;">
                            <v-sheet
                                class="mx-auto"
                            >
                                <v-slide-group
                                multiple
                                show-arrows
                                >
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined max-width="180">
                                            <v-card-text class="pl-4 pr-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <v-icon>
                                                                mdi-sigma
                                                            </v-icon>
                                                            Wgt OH
                                                            <v-spacer></v-spacer>
                                                            <v-checkbox
                                                            v-model="wgt_oh_check"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            @change="getPullData()"
                                                            ></v-checkbox>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" class="mt-3">
                                                        <h6 style="font-weight:600;text-align: center;" class="mt-0 pt-0">
                                                            {{$store.getters.convertToCurrencyUs(total_wgt_oh)}}
                                                        </h6>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined max-width="220">
                                            <v-card-text class="pl-4 pr-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <v-icon>
                                                                mdi-sigma
                                                            </v-icon>
                                                            January
                                                            <v-spacer></v-spacer>
                                                            <v-checkbox
                                                            v-model="jan_check"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            @change="getPullData()"
                                                            ></v-checkbox>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <div>
                                                                <p class="pb-1 mb-0">Order</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_order_jan)}}
                                                                </h6>
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <div>
                                                                <p class="pb-1 mb-0">PO</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_po_jan)}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined max-width="220">
                                            <v-card-text class="pl-4 pr-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <v-icon>
                                                                mdi-sigma
                                                            </v-icon>
                                                            February
                                                            <v-spacer></v-spacer>
                                                            <v-checkbox
                                                            v-model="feb_check"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            @change="getPullData()"
                                                            ></v-checkbox>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <div>
                                                                <p class="pb-1 mb-0">Order</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_order_feb)}}
                                                                </h6>
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <div>
                                                                <p class="pb-1 mb-0">PO</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_po_feb)}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined max-width="220">
                                            <v-card-text class="pl-4 pr-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <v-icon>
                                                                mdi-sigma
                                                            </v-icon>
                                                            March
                                                            <v-spacer></v-spacer>
                                                            <v-checkbox
                                                            v-model="mar_check"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            @change="getPullData()"
                                                            ></v-checkbox>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <div>
                                                                <p class="pb-1 mb-0">Order</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_order_mar)}}
                                                                </h6>
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <div>
                                                                <p class="pb-1 mb-0">PO</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_po_mar)}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined max-width="220">
                                            <v-card-text class="pl-4 pr-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <v-icon>
                                                                mdi-sigma
                                                            </v-icon>
                                                            April
                                                            <v-spacer></v-spacer>
                                                            <v-checkbox
                                                            v-model="apr_check"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            @change="getPullData()"
                                                            ></v-checkbox>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <div>
                                                                <p class="pb-1 mb-0">Order</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_order_apr)}}
                                                                </h6>
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <div>
                                                                <p class="pb-1 mb-0">PO</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_po_apr)}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined max-width="220">
                                            <v-card-text class="pl-4 pr-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <v-icon>
                                                                mdi-sigma
                                                            </v-icon>
                                                            May
                                                            <v-spacer></v-spacer>
                                                            <v-checkbox
                                                            v-model="may_check"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            @change="getPullData()"
                                                            ></v-checkbox>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <div>
                                                                <p class="pb-1 mb-0">Order</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_order_mei)}}
                                                                </h6>
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <div>
                                                                <p class="pb-1 mb-0">PO</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_po_mei)}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined max-width="220">
                                            <v-card-text class="pl-4 pr-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <v-icon>
                                                                mdi-sigma
                                                            </v-icon>
                                                            June
                                                            <v-spacer></v-spacer>
                                                            <v-checkbox
                                                            v-model="jun_check"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            @change="getPullData()"
                                                            ></v-checkbox>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <div>
                                                                <p class="pb-1 mb-0">Order</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_order_jun)}}
                                                                </h6>
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <div>
                                                                <p class="pb-1 mb-0">PO</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_po_jun)}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined max-width="220">
                                            <v-card-text class="pl-4 pr-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <v-icon>
                                                                mdi-sigma
                                                            </v-icon>
                                                            July
                                                            <v-spacer></v-spacer>
                                                            <v-checkbox
                                                            v-model="jul_check"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            @change="getPullData()"
                                                            ></v-checkbox>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <div>
                                                                <p class="pb-1 mb-0">Order</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_order_jul)}}
                                                                </h6>
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <div>
                                                                <p class="pb-1 mb-0">PO</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_po_jul)}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined max-width="220">
                                            <v-card-text class="pl-4 pr-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <v-icon>
                                                                mdi-sigma
                                                            </v-icon>
                                                            August
                                                            <v-spacer></v-spacer>
                                                            <v-checkbox
                                                            v-model="agt_check"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            @change="getPullData()"
                                                            ></v-checkbox>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <div>
                                                                <p class="pb-1 mb-0">Order</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_order_agust)}}
                                                                </h6>
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <div>
                                                                <p class="pb-1 mb-0">PO</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_po_agust)}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined max-width="220">
                                            <v-card-text class="pl-4 pr-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <v-icon>
                                                                mdi-sigma
                                                            </v-icon>
                                                            September
                                                            <v-spacer></v-spacer>
                                                            <v-checkbox
                                                            v-model="sep_check"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            @change="getPullData()"
                                                            ></v-checkbox>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <div>
                                                                <p class="pb-1 mb-0">Order</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_order_sept)}}
                                                                </h6>
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <div>
                                                                <p class="pb-1 mb-0">PO</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_po_sept)}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined max-width="220">
                                            <v-card-text class="pl-4 pr-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <v-icon>
                                                                mdi-sigma
                                                            </v-icon>
                                                            October
                                                            <v-spacer></v-spacer>
                                                            <v-checkbox
                                                            v-model="okt_check"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            @change="getPullData()"
                                                            ></v-checkbox>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <div>
                                                                <p class="pb-1 mb-0">Order</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_order_okt)}}
                                                                </h6>
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <div>
                                                                <p class="pb-1 mb-0">PO</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_po_okt)}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined max-width="220">
                                            <v-card-text class="pl-4 pr-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <v-icon>
                                                                mdi-sigma
                                                            </v-icon>
                                                            November
                                                            <v-spacer></v-spacer>
                                                            <v-checkbox
                                                            v-model="nov_check"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            @change="getPullData()"
                                                            ></v-checkbox>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <div>
                                                                <p class="pb-1 mb-0">Order</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_order_nop)}}
                                                                </h6>
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <div>
                                                                <p class="pb-1 mb-0">PO</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_po_nop)}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                    <v-slide-item class="mx-1">
                                        <v-card class="rounded-l" outlined max-width="220">
                                            <v-card-text class="pl-4 pr-4">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <v-icon>
                                                                mdi-sigma
                                                            </v-icon>
                                                            December
                                                            <v-spacer></v-spacer>
                                                            <v-checkbox
                                                            v-model="des_check"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                            @change="getPullData()"
                                                            ></v-checkbox>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="d-flex">
                                                            <div>
                                                                <p class="pb-1 mb-0">Order</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_order_des)}}
                                                                </h6>
                                                            </div>
                                                            <v-spacer></v-spacer>
                                                            <div>
                                                                <p class="pb-1 mb-0">PO</p>
                                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(total_po_des)}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-slide-item>
                                </v-slide-group>
                            </v-sheet>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="crcs"
                            class="elevation-1"
                            :items-per-page="-1"
                            :loading="$store.state.overlay"
                            height="570"
                            fixed-header
                            :divider="true"
                            :light="true"
                            :item-class="tr_datatable"
                            hide-default-header
                            fixed-footer
                        >   
                            <template #header="{ props: { headers } }">
                                <thead class="v-data-table-header">
                                    <tr>
                                        <th
                                            rowspan="2"
                                            class="text-uppercase text-left"
                                        >
                                            Period
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="text-uppercase text-right"
                                        >
                                            Thick
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="text-uppercase text-right"
                                        >
                                            Width
                                        </th>
                                        <th
                                            rowspan="2"
                                            class="text-uppercase text-right"
                                        >
                                            Wgt OH
                                        </th>
                                        <th
                                            colspan="2"
                                            class="text-uppercase text-center"
                                        >
                                            January
                                        </th>
                                        <th
                                            colspan="2"
                                            class="text-uppercase text-center"
                                        >
                                            February
                                        </th>
                                        <th
                                            colspan="2"
                                            class="text-uppercase text-center"
                                        >
                                            March
                                        </th>
                                        <th
                                            colspan="2"
                                            class="text-uppercase text-center"
                                        >
                                            April
                                        </th>
                                        <th
                                            colspan="2"
                                            class="text-uppercase text-center"
                                        >
                                            May
                                        </th>
                                        <th
                                            colspan="2"
                                            class="text-uppercase text-center"
                                        >
                                            June
                                        </th>
                                        <th
                                            colspan="2"
                                            class="text-uppercase text-center"
                                        >
                                            July
                                        </th>
                                        <th
                                            colspan="2"
                                            class="text-uppercase text-center"
                                        >
                                            August
                                        </th>
                                        <th
                                            colspan="2"
                                            class="text-uppercase text-center"
                                        >
                                            September
                                        </th>
                                        <th
                                            colspan="2"
                                            class="text-uppercase text-center"
                                        >
                                            October
                                        </th>
                                        <th
                                            colspan="2"
                                            class="text-uppercase text-center"
                                        >
                                            November
                                        </th>
                                        <th
                                            colspan="2"
                                            class="text-uppercase text-center"
                                        >
                                            December
                                        </th>
                                    </tr>
                                    <tr>
                                        <th class="text-center">
                                            Order
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Order
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Order
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Order
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Order
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Order
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Order
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Order
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Order
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Order
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Order
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                        <th class="text-center">
                                            Order
                                        </th>
                                        <th class="text-center">
                                            PO
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:[`item.thick`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.thick) }}
                            </template> 
                            <template v-slot:[`item.width`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.width) }}
                            </template>        
                            <template v-slot:[`item.wgt_oh`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.wgt_oh) }}
                            </template>
                            <template v-slot:[`item.jan_ord`]="{ item }">
                                <span v-if="item.jan_ord > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'order', '01', item.jan_ord)">
                                    {{ $store.getters.convertToCurrencyUs(item.jan_ord) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.jan_ord) }}
                                </span>
                            </template>
                            <template v-slot:[`item.jan_po`]="{ item }">
                                <span v-if="item.jan_po > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'po', '01', item.jan_po)">
                                    {{ $store.getters.convertToCurrencyUs(item.jan_po) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.jan_po) }}
                                </span>
                            </template>
                            <template v-slot:[`item.feb_ord`]="{ item }">
                                <span v-if="item.feb_ord > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'order', '02', item.feb_ord)">
                                    {{ $store.getters.convertToCurrencyUs(item.feb_ord) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.feb_ord) }}
                                </span>
                            </template>
                            <template v-slot:[`item.feb_po`]="{ item }">
                                <span v-if="item.feb_po > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'po', '02', item.feb_po)">
                                    {{ $store.getters.convertToCurrencyUs(item.feb_po) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.feb_po) }}
                                </span>
                            </template>    
                            <template v-slot:[`item.mar_ord`]="{ item }">
                                <span v-if="item.mar_ord > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'order', '03', item.mar_ord)">
                                    {{ $store.getters.convertToCurrencyUs(item.mar_ord) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.mar_ord) }}
                                </span>
                            </template>
                            <template v-slot:[`item.mar_po`]="{ item }">
                                <span v-if="item.mar_po > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'po', '03', item.mar_po)">
                                    {{ $store.getters.convertToCurrencyUs(item.mar_po) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.mar_po) }}
                                </span>
                            </template>
                            <template v-slot:[`item.apr_ord`]="{ item }">
                                <span v-if="item.apr_ord > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'order', '04', item.apr_ord)">
                                    {{ $store.getters.convertToCurrencyUs(item.apr_ord) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.apr_ord) }}
                                </span>
                            </template>
                            <template v-slot:[`item.apr_po`]="{ item }">
                                <span v-if="item.apr_po > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'po', '04', item.apr_po)">
                                    {{ $store.getters.convertToCurrencyUs(item.apr_po) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.apr_po) }}
                                </span>
                            </template>
                            <template v-slot:[`item.mei_ord`]="{ item }">
                                <span v-if="item.mei_ord > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'order', '05', item.mei_ord)">
                                    {{ $store.getters.convertToCurrencyUs(item.mei_ord) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.mei_ord) }}
                                </span>
                            </template>
                            <template v-slot:[`item.mei_po`]="{ item }">
                                <span v-if="item.mei_po > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'po', '05', item.mei_po)">
                                    {{ $store.getters.convertToCurrencyUs(item.mei_po) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.mei_po) }}
                                </span>
                            </template>
                            <template v-slot:[`item.jun_ord`]="{ item }">
                                <span v-if="item.jun_ord > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'order', '06', item.jun_ord)">
                                    {{ $store.getters.convertToCurrencyUs(item.jun_ord) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.jun_ord) }}
                                </span>
                            </template>
                            <template v-slot:[`item.jun_po`]="{ item }">
                                <span v-if="item.jun_po > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'po', '06', item.jun_po)">
                                    {{ $store.getters.convertToCurrencyUs(item.jun_po) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.jun_po) }}
                                </span>
                            </template>
                            <template v-slot:[`item.jul_ord`]="{ item }">
                                <span v-if="item.jul_ord > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'order', '07', item.jul_ord)">
                                    {{ $store.getters.convertToCurrencyUs(item.jul_ord) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.jul_ord) }}
                                </span>
                            </template>
                            <template v-slot:[`item.jul_po`]="{ item }">
                                <span v-if="item.jul_po > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'po', '07', item.jul_po)">
                                    {{ $store.getters.convertToCurrencyUs(item.jul_po) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.jul_po) }}
                                </span>
                            </template>
                            <template v-slot:[`item.agt_ord`]="{ item }">
                                <span v-if="item.agt_ord > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'order', '08', item.agt_ord)">
                                    {{ $store.getters.convertToCurrencyUs(item.agt_ord) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.agt_ord) }}
                                </span>
                            </template>
                            <template v-slot:[`item.agt_po`]="{ item }">
                                <span v-if="item.agt_po > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'po', '08', item.agt_po)">
                                    {{ $store.getters.convertToCurrencyUs(item.agt_po) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.agt_po) }}
                                </span>
                            </template>
                            <template v-slot:[`item.sep_ord`]="{ item }">
                                <span v-if="item.sep_ord > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'order', '09', item.sep_ord)">
                                    {{ $store.getters.convertToCurrencyUs(item.sep_ord) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.sep_ord) }}
                                </span>
                            </template>
                            <template v-slot:[`item.sep_po`]="{ item }">
                                <span v-if="item.sep_po > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'po', '09', item.sep_po)">
                                    {{ $store.getters.convertToCurrencyUs(item.sep_po) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.sep_po) }}
                                </span>
                            </template>
                            <template v-slot:[`item.okt_ord`]="{ item }">
                                <span v-if="item.okt_ord > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'order', '10', item.okt_ord)">
                                    {{ $store.getters.convertToCurrencyUs(item.okt_ord) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.okt_ord) }}
                                </span>
                            </template>
                            <template v-slot:[`item.okt_po`]="{ item }">
                                <span v-if="item.okt_po > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'po', '10', item.okt_po)">
                                    {{ $store.getters.convertToCurrencyUs(item.okt_po) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.okt_po) }}
                                </span>
                            </template>
                            <template v-slot:[`item.nov_ord`]="{ item }">
                                <span v-if="item.nov_ord > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'order', '11', item.nov_ord)">
                                    {{ $store.getters.convertToCurrencyUs(item.nov_ord) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.nov_ord) }}
                                </span>
                            </template>
                            <template v-slot:[`item.nov_po`]="{ item }">
                                <span v-if="item.nov_po > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'po', '11', item.nov_po)">
                                    {{ $store.getters.convertToCurrencyUs(item.nov_po) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.nov_po) }}
                                </span>
                            </template>
                            <template v-slot:[`item.des_ord`]="{ item }">
                                <span v-if="item.des_ord > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'order', '12', item.des_ord)">
                                    {{ $store.getters.convertToCurrencyUs(item.des_ord) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.des_ord) }}
                                </span>
                            </template>
                            <template v-slot:[`item.des_po`]="{ item }">
                                <span v-if="item.des_po > 0" style="text-align: right;text-decoration:underline;cursor:pointer" @click="showDetail(item, 'po', '12', item.des_po)">
                                    {{ $store.getters.convertToCurrencyUs(item.des_po) }}
                                </span>
                                <span v-else>
                                    {{ $store.getters.convertToCurrencyUs(item.des_po) }}
                                </span>
                            </template>
                            <template slot="body.append">
                                <tr>
                                    <th colspan="3">Total</th>
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_wgt_oh)}}</th><!-- WGT OH */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_order_jan)}}</th> <!-- Order */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_po_jan)}}</th> <!-- PO */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_order_feb)}}</th> <!-- Order */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_po_feb)}}</th> <!-- PO */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_order_mar)}}</th> <!-- Order */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_po_mar)}}</th> <!-- PO */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_order_apr)}}</th> <!-- Order */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_po_apr)}}</th> <!-- PO */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_order_mei)}}</th> <!-- Order */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_po_mei)}}</th> <!-- PO */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_order_jun)}}</th> <!-- Order */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_po_jun)}}</th> <!-- PO */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_order_jul)}}</th> <!-- Order */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_po_jul)}}</th> <!-- PO */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_order_agust)}}</th> <!-- Order */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_po_agust)}}</th> <!-- PO */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_order_sept)}}</th> <!-- Order */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_po_sept)}}</th> <!-- PO */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_order_okt)}}</th> <!-- Order */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_po_okt)}}</th> <!-- PO */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_order_nop)}}</th> <!-- Order */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_po_nop)}}</th> <!-- PO */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_order_des)}}</th> <!-- Order */ -->
                                    <th class="text-right">{{$store.getters.convertToCurrencyUs(total_po_des)}}</th> <!-- PO */ -->
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-show="displayItem === 'block' ? true : false">
            <v-col cols="12">
                <v-card class="elevation-5 rounded-l" outlined>
                    <v-card-title >
                        <h6 style="font-weight:600">{{jenis === 'po' ? 'PO Outstandings : ' : 'Order Outstandings : ' }} {{ jenis === 'po' ?  $store.getters.convertToCurrencyUs(wgt_po) + ' Kg' :  $store.getters.convertToCurrencyUs(wgt_order) + ' Kg' }}</h6>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="close()"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            dense
                            :headers="header_details"
                            :items="details"
                            class="elevation-1 mt-2"
                            :footer-props="{
                            'items-per-page-options': [10, 20, 30, 40, 50]
                            }"
                            :items-per-page="30"
                            :loading="loading_detail"
                            height="578"
                            fixed-header
                            :divider="true"
                            :light="true"
                        >
                            <template v-slot:[`item.thick`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.thick) }} mm
                            </template>
                            <template v-slot:[`item.width`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.width) }} mm 
                            </template>
                            <template v-slot:[`item.wgt_out`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.wgt_out) }} Kg
                            </template>
                            <template v-slot:[`item.period`]="{ item }">
                                {{ item.req_year }} - {{ item.req_month }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data() {
        return {
            year: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Production Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'CRC Procurement',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            headers:[
                {
                    text: 'Period',
                    align: 'start',
                    value: 'period', 
                    align:'left'},
                { text: 'Thick', value: 'thick' , align:'right'},
                { text: 'Width', value: 'width' , align:'right'},
                { text: 'Wgt OH', value: 'wgt_oh' , align:'right'},
                { text: 'jan_ord', value: 'jan_ord' , align:'right'},
                { text: 'jan_po', value: 'jan_po' , align:'right'},
                { text: 'feb_ord', value: 'feb_ord' , align:'right'},
                { text: 'feb_po', value: 'feb_po' , align:'right'},
                { text: 'mar_ord', value: 'mar_ord' , align:'right'},
                { text: 'mar_po', value: 'mar_po' , align:'right'},
                { text: 'apr_ord', value: 'apr_ord' , align:'right'},
                { text: 'apr_po', value: 'apr_po' , align:'right'},
                { text: 'mei_ord', value: 'mei_ord' , align:'right'},
                { text: 'mei_po', value: 'mei_po' , align:'right'},
                { text: 'jun_ord', value: 'jun_ord' , align:'right'},
                { text: 'jun_po', value: 'jun_po' , align:'right'},
                { text: 'jul_ord', value: 'jul_ord' , align:'right'},
                { text: 'jul_po', value: 'jul_po' , align:'right'},
                { text: 'agt_ord', value: 'agt_ord' , align:'right'},
                { text: 'agt_po', value: 'agt_po' , align:'right'},
                { text: 'sep_ord', value: 'sep_ord' , align:'right'},
                { text: 'sep_po', value: 'sep_po' , align:'right'},
                { text: 'okt_ord', value: 'okt_ord' , align:'right'},
                { text: 'okt_po', value: 'okt_po' , align:'right'},
                { text: 'nov_ord', value: 'nov_ord' , align:'right'},
                { text: 'nov_po', value: 'nov_po' , align:'right'},
                { text: 'des_ord', value: 'des_ord' , align:'right'},
                { text: 'des_po', value: 'des_po' , align:'right'}
            ],
            crcs: [],
            dialog: false,
            thick:'',
            thicks: [],
            width:'',
            widths: [],
            menu: false,
            picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date:'',
            jenis: '',
            wgt_po: 0,
            wgt_order: 0,
            header_details: [],
            details: [],
            loading_detail: false,
            display: 'block',
            displayItem: 'none',
            detailItem: null,
            wgt_oh_check: false,
            jan_check: false,
            feb_check: false,
            mar_check: false,
            apr_check: false,
            may_check: false,
            jun_check: false,
            jul_check: false,
            agt_check: false,
            sep_check: false,
            okt_check: false,
            nov_check: false,
            des_check: false,
        }
    },
    async mounted(){
        await this.getPullData()
        await this.getThick()
        await this.getWidth()
        this.$refs.picker.activePicker = 'YEAR'   
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        clear(){
            this.thick = ''
            this.width = ''
            this.year = ''
        },
        close(){
            this.display = 'block'
            this.displayItem = 'none'
            this.header_details = []
            this.details = []
            this.getPullData()
        },
        save(date) {
            this.$refs.menu.save(date)

            this.$refs.picker.activePicker = 'YEAR'

            this.menu = false

            this.year = new Date(date.substr(0, 4)).toISOString().substr(0, 4)
        },
        async getPullData(){
            this.crcs = []
            this.$store.dispatch('setOverlay', true)

            const respData = await backendApi.fetchCore(`/api/sr/crc_procurement?year=${this.year ? this.year : ''}&thick=${this.thick ? this.thick : ''}&width=${this.width ? this.width : ''}&wgt_oh_check=${this.wgt_oh_check === true ? true : ''}&jan_check=${this.jan_check === true ? true : ''}&feb_check=${this.feb_check === true ? true : ''}&mar_check=${this.mar_check === true ? true : ''}&apr_check=${this.apr_check === true ? true : ''}&may_check=${this.may_check === true ? true : ''}&jun_check=${this.jun_check === true ? true : ''}&jul_check=${this.jul_check === true ? true : ''}&agt_check=${this.agt_check === true ? true : ''}&sep_check=${this.sep_check === true ? true : ''}&okt_check=${this.okt_check === true ? true : ''}&nov_check=${this.nov_check === true ? true : ''}&des_check=${this.des_check === true ? true : ''}`, '', false, false, false)

            if (respData.status === 200) {
                this.crcs = respData.data
                this.$store.dispatch('setOverlay', false)
            }
        },
        async getThick(){
            const respData = await backendApi.fetchCore(`/api/sr/crc_procurement/getThick`, '', false, false, false)

            if (respData.status === 200) {
                this.thicks = respData.data
            }
        },
        async getWidth(){
            const respData = await backendApi.fetchCore(`/api/sr/crc_procurement/getWidth`, '', false, false, false)

            if (respData.status === 200) {
                this.widths = respData.data
            }
        },
        async showDetail(item, jenis, req_month, value){
            console.log(item, req_month);
            this.header_details = []
            this.details = []
            this.loading_detail = true
            this.detailItem = item
            this.jenis = jenis
            this.display = 'none'
            this.displayItem = 'block'
            
            var reqBody = {
                'thick': item.thick,
                'width': item.width,
                'req_year': item.period,
                'req_month': req_month
            }
            if (jenis === 'po') {
                this.wgt_po = value
                this.header_details  = [
                    { text: 'PO ID', value: 'po_id', align:'left' },
                    { text: 'Vendor', value: 'vendor_name', align:'left' },
                    { text: 'Period', value: 'period', align:'left' },                    
                    { text: 'Prodcode', value: 'prod_code', align:'left' },
                    { text: 'Product', value: 'article', align:'left' },
                    { text: 'Thick', value: 'thick', align:'right' },
                    { text: 'Width', value: 'width', align:'right' },
                    { text: 'Wgt Out', value: 'wgt_out', align:'right' }
                ]

                const respData = await backendApi.fetchCore('/v2/sr/crc-avail-daily/showPO', reqBody, false, false, false)
                
                if (respData.status === 200) {
                    this.loading_detail = false
                    this.details = respData.data.data
                }
            } else {
                this.wgt_order = value

                this.header_details  = [
                    { text: 'Order ID', value: 'order_id', align:'left' },
                    { text: 'Region', value: 'region', align:'left' },
                    { text: 'Customer', value: 'cust_name', align:'left' },
                    { text: 'Period', value: 'period', align:'left' },                    
                    { text: 'Prodcode', value: 'prod_code', align:'left' },
                    { text: 'Product', value: 'article', align:'left' },
                    { text: 'Thick', value: 'thick', align:'right' },
                    { text: 'Width', value: 'width', align:'right' },
                    { text: 'Wgt Out', value: 'wgt_out', align:'right' }
                ]

                const respDataOrder = await backendApi.fetchCore('/v2/sr/crc-avail-daily/showOrder', reqBody, false, false, false)
                
                if (respDataOrder.status === 200) {
                    this.loading_detail = false
                    this.details = respDataOrder.data.data
                }
            }
        },
    },
    watch: {
        menu (val) {
            console.log(val);
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
    },
    computed:{
        total_wgt_oh(){
            var total_wgt_oh = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_wgt_oh = total_wgt_oh + Number(this.crcs[index].wgt_oh);
            }
            
            return (total_wgt_oh | 0)
        },
        total_order_jan(){
            var total_order_jan = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_order_jan = total_order_jan + Number(this.crcs[index].jan_ord);
            }
            
            return (total_order_jan | 0)
        },
        total_po_jan(){
            var total_po_jan = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_po_jan = total_po_jan + Number(this.crcs[index].jan_po);
            }
            
            return (total_po_jan | 0)
        },
        total_order_feb(){
            var total_order_feb = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_order_feb = total_order_feb + Number(this.crcs[index].feb_ord);
            }
            
            return (total_order_feb | 0)
        },
        total_po_feb(){
            var total_po_feb = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_po_feb = total_po_feb + Number(this.crcs[index].feb_po);
            }
            
            return (total_po_feb | 0)
        },
        total_order_mar(){
            var total_order_mar = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_order_mar = total_order_mar + Number(this.crcs[index].mar_ord);
            }
            
            return (total_order_mar | 0)
        },
        total_po_mar(){
            var total_po_mar = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_po_mar = total_po_mar + Number(this.crcs[index].mar_po);
            }
            
            return (total_po_mar | 0)
        },
        total_order_apr(){
            var total_order_apr = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_order_apr = total_order_apr + Number(this.crcs[index].apr_ord);
            }
            
            return (total_order_apr | 0)
        },
        total_po_apr(){
            var total_po_apr = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_po_apr = total_po_apr + Number(this.crcs[index].apr_po);
            }
            
            return (total_po_apr | 0)
        },
        total_order_mei(){
            var total_order_mei = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_order_mei = total_order_mei + Number(this.crcs[index].mei_ord);
            }
            
            return (total_order_mei | 0)
        },
        total_po_mei(){
            var total_po_mei = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_po_mei = total_po_mei + Number(this.crcs[index].mei_po);
            }
            
            return (total_po_mei | 0)
        },
        total_order_jun(){
            var total_order_jun = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_order_jun = total_order_jun + Number(this.crcs[index].jun_ord);
            }
            
            return (total_order_jun | 0)
        },
        total_po_jun(){
            var total_po_jun = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_po_jun = total_po_jun + Number(this.crcs[index].jun_po);
            }
            
            return (total_po_jun | 0)
        },
        total_order_jul(){
            var total_order_jul = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_order_jul = total_order_jul + Number(this.crcs[index].jul_ord);
            }
            
            return (total_order_jul | 0)
        },
        total_po_jul(){
            var total_po_jul = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_po_jul = total_po_jul + Number(this.crcs[index].jul_po);
            }
            
            return (total_po_jul | 0)
        },
        total_order_agust(){
            var total_order_agust = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_order_agust = total_order_agust + Number(this.crcs[index].agt_ord);
            }
            
            return (total_order_agust | 0)
        },
        total_po_agust(){
            var total_po_agust = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_po_agust = total_po_agust + Number(this.crcs[index].agt_po);
            }
            
            return (total_po_agust | 0)
        },
        total_order_sept(){
            var total_order_sept = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_order_sept = total_order_sept + Number(this.crcs[index].sep_ord);
            }
            
            return (total_order_sept | 0)
        },
        total_po_sept(){
            var total_po_sept = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_po_sept = total_po_sept + Number(this.crcs[index].sep_po);
            }
            
            return (total_po_sept | 0)
        },
        total_order_okt(){
            var total_order_okt = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_order_okt = total_order_okt + Number(this.crcs[index].okt_ord);
            }
            
            return (total_order_okt | 0)
        },
        total_po_okt(){
            var total_po_okt = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_po_okt = total_po_okt + Number(this.crcs[index].okt_po);
            }
            
            return (total_po_okt | 0)
        },
        total_order_nop(){
            var total_order_nop = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_order_nop = total_order_nop + Number(this.crcs[index].nov_ord);
            }
            
            return (total_order_nop | 0)
        },
        total_po_nop(){
            var total_po_nop = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_po_nop = total_po_nop + Number(this.crcs[index].nov_po);
            }
            
            return (total_po_nop | 0)
        },
        total_order_des(){
            var total_order_des = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_order_des = total_order_des + Number(this.crcs[index].des_ord);
            }
            
            return (total_order_des | 0)
        },
        total_po_des(){
            var total_po_des = 0
            for (let index = 0; index < this.crcs.length; index++) {
                total_po_des = total_po_des + Number(this.crcs[index].des_po);
            }
            
            return (total_po_des | 0)
        },
    }
}
</script>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}

.theme--light.v-data-table.v-data-table--fixed-header thead th {
    background: #FFFFFF;
    border: 1px solid rgba(31, 31, 31, 0.12);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 12%);
}
</style>